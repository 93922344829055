import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { FC, FormEvent, SyntheticEvent, useCallback, useState } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import srcSecurity from '../../assets/icon-security-check-primary.svg';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import { pinCodeMaskOption } from '../../components/Form/utils';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import Backdrop from '../../components/Modal/Backdrop';
import Modal from '../../components/Modal/Modal';
import PinResendButton from '../../components/PinResendButton';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import { loginWithOtp, sendLoginOTP } from '../../redux/auth';
import { sanitizeOTP } from '../../utils';
import client from '../../vendors/clientjs';
import styles from './TwoFAScreen.module.scss';

const TwoFAScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();

  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const loginAttemptId = useAppSelector((state) => state.auth.loginAttemptId);
  const phoneLast4 = useAppSelector((state) => state.auth.phoneLast4);
  const testOTPSecret = useAppSelector((state) => state.auth.testOTPSecret);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState<APIPayload['details']>([]);
  const [otpSecret, setOtpSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [showPhoneNotFunctionalModal, setShowPhoneNotFunctionalModal] =
    useState(false);

  const { onLogin } = useOnLogin({
    onFailure: (errorCode) =>
      setErrorMessage(
        ERROR_MESSAGES.SHARED[errorCode] || ERROR_MESSAGES.SHARED.unknown
      ),
  });

  const onResendOtp = useCallback(
    (method) =>
      loginAttemptId && dispatch(sendLoginOTP({ loginAttemptId, method })),
    [dispatch, loginAttemptId]
  );

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Submit',
    });

    if (testOTPSecret || otpSecret) {
      setIsLoading(true);

      const loginResultAction = await dispatch(
        loginWithOtp({
          loginAttemptId,
          otpSecret: sanitizeOTP(testOTPSecret || otpSecret),
        })
      );

      if (loginWithOtp.fulfilled.match(loginResultAction)) {
        await onLogin(loginResultAction.payload);
      } else if ((loginResultAction.payload as APIPayload)?.message) {
        setErrorMessage(
          ERROR_MESSAGES['2FA'][
            (loginResultAction.payload as APIPayload)?.errorCode
          ] ||
            ERROR_MESSAGES.PIN[
              (loginResultAction.payload as APIPayload)?.errorCode
            ] ||
            ERROR_MESSAGES.SHARED.unknown
        );
        setErrorDetails((loginResultAction.payload as APIPayload)?.details);
      } else {
        setErrorMessage(
          ERROR_MESSAGES['2FA'][
            (loginResultAction.payload as APIPayload)?.errorCode
          ] ||
            ERROR_MESSAGES.PIN[
              (loginResultAction.payload as APIPayload)?.errorCode
            ] ||
            ERROR_MESSAGES.SHARED.unknown
        );
      }
      setIsLoading(false);
    }
  };

  const onUpdateOtpSecret = (event: FormEvent<HTMLInputElement>) => {
    setErrorDetails(
      errorDetails.filter((detail) => detail.path !== 'otpSecret')
    );

    setOtpSecret(event.currentTarget.value);
  };

  const onCallSupport = useCallback(() => {
    document.location.href = 'tel:05038166723';
  }, []);

  return (
    <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
      <aside>
        <Header />
        <MerchantHeader />
      </aside>
      <MainLayout greetings="アカウント認証">
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles['opt-secret-box']}>
            <p>
              <em>•••••••{phoneLast4}</em>{' '}
              へ送信された6桁のセキュリティコードを入力してください。
            </p>
            <button
              type="button"
              className={styles['phone-not-functional']}
              onClick={() => setShowPhoneNotFunctionalModal(true)}
            >
              <img src={srcSecurity} alt="" width="16" height="16" />
              登録済みの電話番号は使用できませんか？
            </button>
            <TextInput
              name="otpSecret"
              pattern="\d{3}-\d{3}"
              onChange={onUpdateOtpSecret}
              value={otpSecret}
              aria-label="セキュリティコード"
              className={styles['opt-secret']}
              placeholder="888-888"
              maskOption={pinCodeMaskOption}
              autoComplete="one-time-code"
              {...(errorDetails.find(
                (detail) => detail.path === 'otpSecret'
              ) && {
                errorMessage,
              })}
              {...(testOTPSecret && {
                value: testOTPSecret,
              })}
            />
            {errorDetails.length === 0 && errorMessage && (
              <div className={styles['error-wrapper']}>
                <p>{errorMessage}</p>
              </div>
            )}
            <PinResendButton onClick={onResendOtp} />
          </div>
          <Button
            id="btn_submit"
            loading={isLoading}
            type="submit"
            label="次へ"
            disabled={!(testOTPSecret || (otpSecret && otpSecret.length === 7))}
          />
        </form>
      </MainLayout>
      {showPhoneNotFunctionalModal && (
        <>
          <Backdrop />
          <Modal>
            <div className={styles['phone-not-functional-modal']}>
              <h5 className={styles.title}>
                登録済みの電話番号は使用できませんか？
              </h5>
              <p className={styles.message}>
                携帯電話番号は、Smartpayアプリの設定画面から変更できます。
                <br />
                Smartpayに登録した携帯電話番号が使用できない場合は、カスタマーサポート（050-3537-4983）までお電話ください。
              </p>
              <div className={styles.actions}>
                {client.isMobile() && (
                  <Button
                    id="btn_call_support"
                    type="button"
                    label="カスタマーサポートに電話する"
                    variant="outline"
                    onClick={onCallSupport}
                    className={styles.action}
                  />
                )}
                <Button
                  id="btn_submit"
                  type="button"
                  label="OK"
                  onClick={() => setShowPhoneNotFunctionalModal(false)}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default TwoFAScreen;

import cx from 'classnames';
import styles from './Message.module.scss';

type Props = {
  type?: 'info' | 'warning' | 'danger';
  message: string;
};

const Message = ({ type = 'info', message }: Props) => {
  return <div className={cx(styles.message, styles[type])}>{message}</div>;
};

export default Message;

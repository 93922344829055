import { navigate, RouteComponentProps } from '@reach/router';
import { AsyncThunk } from '@reduxjs/toolkit';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { addMonths, subDays } from 'date-fns/esm';
import format from 'date-fns/format';
import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import PaymentAPI from '../../api/payment';
import { APIPayload } from '../../api/types';
import iconStepArrow from '../../assets/step-arrow.svg';
import AlertModal from '../../components/AlertModal/AlertModal';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import PaymentMethodModal from '../../components/PaymentMethodModal/PaymentMethodModal';
import useAppSelector from '../../hooks/use-app-selector';
import useInterval from '../../hooks/use-interval';
import useMaintenanceCheck from '../../hooks/use-maintenance-check';
import useSessionStatus from '../../hooks/use-order-status';
import usePaymentMethods from '../../hooks/use-payment-methods';
import { updateAuthPairs } from '../../redux/auth';
import { setHasAppliedPromotionCode } from '../../redux/good';
import {
  addPaymentMethod,
  authorizeOrder,
  reserveDiscount,
  selectPaymentMethod,
} from '../../redux/payment-method';
import { currencyFormatter } from '../../utils';
import createEventHandler from '../../utils/create-event-handler';
import delayUIResponse from '../../utils/delay-ui-response';
import getTotalAmount from '../../utils/get-total-amount';
import {
  getAmountForTheFirst,
  getAmountForTheRest,
} from '../../utils/installments';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import ChallengeScreen from './ChallengeScreen/ChallengeScreen';
import PayActionForm from './PayActionForm';
import PaymentLoadingScreen from './PaymentLoadingScreen';
import styles from './PaymentScreen.module.scss';

type ScreenState = 'idle' | 'processing' | 'challenging';

type PaymentRequestT = typeof PaymentRequest & {
  onmerchantvalidation: (event: {
    complete: (session: Promise<string>) => void;
  }) => void;
  show: () => {
    details: {
      token: {
        paymentMethod: {
          network: string;
          displayName: string;
        };
        paymentData: {
          data: string;
        };
      };
    };
    complete: (arg: string) => void;
  };
};

export type PaymentCallbacks = {
  onPaymentSuccess?: (() => void) | null;
  onPaymentFailure?: (() => void) | null;
};

export type AuthorizeOrderRequest = {
  orderId?: string;
  paymentMethodId: string;
  expectedDiscounts?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalDetail?: any;
};

export const PAYMENT_SCREEN_IDLE: ScreenState = 'idle';
export const PAYMENT_SCREEN_PROCESSING: ScreenState = 'processing';
export const PAYMENT_SCREEN_CHALLENGING: ScreenState = 'challenging';

const ORDER_STATUS_CHECK_INTERVAL = 15000; // 15 seconds
const COMMON_REJECTION_MESSAGE =
  'カード処理エラーが発生しました。もう一度お試しください。それでも問題が解決しない場合は、別のカードをお試しください。';

const getCardRejectionMessage = (errorCode: string | undefined): string => {
  switch (errorCode) {
    case 'credit': {
      return 'クレジットカードの上限金額に達しため、利用が拒否されました。別のカードを選択するか、新しいカードを追加してください。';
    }
    case 'expiry': {
      return 'クレジットカードの有効期限が３ヶ月未満なため、ご利用頂けません。別のカードを選択するか、新しいカードを追加してください。';
    }
    case 'prepaid': {
      return 'デビットカードとプリペイドカードはご利用いただけません。別のカードを選択するか、新しいカードを追加してください。';
    }
    case 'debit': {
      return 'デビットカードでの支払いが現在一時的にご利用いただけません。クレジットカードか銀行口座引き落としをご利用ください。';
    }
    case 'cvc': {
      return 'クレジットカードのセキュリティーコード（CVCコード）が正しくないためご利用頂けません。ご利用のクレジットカードをご確認の上、再度ご登録ください。';
    }
    case 'invalid_card_data': {
      return 'クレジットカードによる決済処理が完了できませんでした。ご利用のクレジットカードをご確認の上、再度ご登録しなおすか、新しいカードを追加してください。';
    }
    case '3ds_authentication_failed': {
      return '認証に失敗しました。入力内容をご確認いただき、もう一度お試しください。';
    }
    case '3ds_authentication_canceled': {
      return '';
    }
    default: {
      return errorCode ? COMMON_REJECTION_MESSAGE : '';
    }
  }
};

const PaymentScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();

  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const merchantName = useAppSelector((state) => state.misc.merchantName);
  const rememberMe = useAppSelector((state) => state.misc.rememberMe);
  const goods = useAppSelector((state) => state.good.data);
  const discountId = useAppSelector((state) => state.paymentMethod.discountId);
  const hasAppliedPromotionCode = useAppSelector(
    (state) => state.good.hasAppliedPromotionCode
  );
  const promotionCode = useAppSelector((state) => state.misc.promotionCode);
  const promotion = useAppSelector((state) => state.misc.promotion);
  const discountAmount = useAppSelector(
    (state) => state.paymentMethod.discountAmount
  );
  const { isThreeDSEnabled, isForceThreeDS } = useAppSelector(
    (state) => state.featureFlag
  );

  const { selectedPaymentMethod, paymentMethods } = usePaymentMethods();
  const { checkBankDirectMaintenance } = useMaintenanceCheck();

  const [screenState, setScreenState] =
    useState<ScreenState>(PAYMENT_SCREEN_IDLE);

  const [errorMessage, setErrorMessage] = useState<string>();
  // const [showCCRequiredModal, setShowCCRequiredModal] = useState(false);
  const [showMethodModal, setShowMethodModal] = useState(false);

  const [showBankDirectMaintenanceModal, setShowBankDirectMaintenanceModal] =
    useState(false);
  const [bankDirectMaintenanceMessage, setBankDirectMaintenanceMessage] =
    useState('');
  const callbacksRef = useRef<PaymentCallbacks>({});

  const verifyOrderStatus = useSessionStatus();

  const { totalAmount, isValidPromotion } = getTotalAmount(
    goods,
    hasAppliedPromotionCode,
    discountAmount,
    promotion
  );

  const onSubmitStart = createEventHandler(
    ({ onPaymentSuccess, onPaymentFailure }: PaymentCallbacks = {}) => {
      setScreenState(
        isForceThreeDS || isThreeDSEnabled
          ? PAYMENT_SCREEN_CHALLENGING
          : PAYMENT_SCREEN_PROCESSING
      );

      callbacksRef.current.onPaymentSuccess = onPaymentSuccess;
      callbacksRef.current.onPaymentFailure = onPaymentFailure;
    }
  );

  const authorizeResultHandler = createEventHandler(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (resultAction: any, theThunk: AsyncThunk<any, any, any>) => {
      const { onPaymentSuccess, onPaymentFailure } = callbacksRef.current;

      delayUIResponse(async () => {
        if (theThunk.fulfilled.match(resultAction)) {
          const { status, publicRejectionCode, threeDS } = resultAction.payload;

          switch (status) {
            case 'succeeded':
            case 'requires_capture':
              await onPaymentSuccess?.();
              navigate('/payment-success');

              break;
            case 'requires_authorization':
              if (threeDS) {
                // To avoid it blocks another authorization call
                // The status in the middle of a 3DS is still 'requires_authorization'
                setScreenState(PAYMENT_SCREEN_CHALLENGING);
              } else {
                await onPaymentFailure?.();
                setErrorMessage(
                  getCardRejectionMessage(
                    publicRejectionCode || 'unexpected_error'
                  )
                );
                setScreenState(PAYMENT_SCREEN_IDLE);
              }

              break;
            default:
              // rejected, Hard Decline
              await onPaymentFailure?.();

              switch (publicRejectionCode) {
                case 'past_due_charges':
                  navigate('/error/payment-overdue-installments');

                  break;
                case 'insufficient_balance':
                  navigate('/error/payment-over-limit');

                  break;
                default:
                  navigate('/error/payment-rejected');
              }
          }
        } else {
          const errorCode = (resultAction.payload as APIPayload)?.errorCode;

          await onPaymentFailure?.();

          switch (errorCode) {
            case 'order.duplicate-rejection':
            case 'checkout-session.expired':
              navigate('/error/payment-rejected');

              break;
            case 'order.authorized':
            case 'order.cannot-authorize':
            case 'order.duplicate-authorization':
              setErrorMessage(ERROR_MESSAGES.PAYMENT['order.authorized']);
              setScreenState(PAYMENT_SCREEN_IDLE);

              break;
            case 'order.reserved-discount.not-found':
              dispatch(setHasAppliedPromotionCode(false));
              setErrorMessage(
                ERROR_MESSAGES.PAYMENT['order.reserved-discount.not-found']
              );
              setScreenState(PAYMENT_SCREEN_IDLE);

              break;
            default:
              setErrorMessage(
                ERROR_MESSAGES.PAYMENT[errorCode] ||
                  ERROR_MESSAGES.SHARED.unknown
              );
              setScreenState(PAYMENT_SCREEN_IDLE);
          }
        }
      });
    }
  );

  const onSubmitEnd = createEventHandler(async (paymentMethodId?: string) => {
    if (!paymentMethodId) {
      setScreenState(PAYMENT_SCREEN_IDLE);

      return;
    }

    if (paymentMethodId === 'bank-account-id') {
      navigate('/bank-direct');

      return;
    }

    const resultAction = await dispatch(
      authorizeOrder({
        orderID: goods.id || '',
        paymentMethodID: paymentMethodId,
        ...(hasAppliedPromotionCode &&
          discountId && {
            expectedDiscounts: [discountId],
          }),
      })
    );

    authorizeResultHandler(resultAction, authorizeOrder);
  });

  const onTriggerMethodSelector = useCallback(() => {
    setShowMethodModal(true);
  }, []);

  const onSelectPaymentMethod = createEventHandler((value: string | null) => {
    if (value) {
      dispatch(selectPaymentMethod(value));
      setShowMethodModal(false);

      Mixpanel.trackAction({
        action: 'Click',
        itemName: 'Select a Card',
      });
    }
  });

  const onPay = createEventHandler(async () => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Submit',
    });

    if (selectedPaymentMethod?.id === 'bank-account-id') {
      const bankDirectMaintenance = checkBankDirectMaintenance();

      if (
        bankDirectMaintenance &&
        bankDirectMaintenance.isBankDirectUnderMaintenance
      ) {
        setShowBankDirectMaintenanceModal(true);
        setBankDirectMaintenanceMessage(bankDirectMaintenance.jaMessage);

        return;
      }
    }

    onSubmitStart();

    await onSubmitEnd(selectedPaymentMethod?.id);
  });

  const googlePaymentRequest: google.payments.api.PaymentDataRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'adyen',
            gatewayMerchantId:
              process.env.REACT_APP_GOOGLE_PAY_GATEWAY_MERCHANT_ID || '',
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID || '',
      merchantName: 'Smartpay',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: `${getAmountForTheFirst(goods.amount || 0)}`,
      currencyCode: 'JPY',
      countryCode: 'JP',
    },
  };

  const onGooglePay = createEventHandler(
    async (paymentData: google.payments.api.PaymentData) => {
      Mixpanel.trackAction({
        action: 'Click',
        itemName: 'Submit | Google Pay',
      });

      try {
        const addCardResultAction = await dispatch(
          addPaymentMethod({
            kind: 'google_pay',
            brand: [...(paymentData.paymentMethodData.info?.cardNetwork || [])]
              .map((x: string) => x.toLowerCase?.())
              .join(''),
            last4: paymentData.paymentMethodData.info?.cardDetails,
            googlePayToken:
              paymentData.paymentMethodData.tokenizationData?.token,
          })
        );

        delayUIResponse(async () => {
          if (addPaymentMethod.fulfilled.match(addCardResultAction)) {
            await onSubmitEnd(addCardResultAction.payload.id);
          } else {
            setScreenState(PAYMENT_SCREEN_IDLE);
            setErrorMessage(COMMON_REJECTION_MESSAGE);
          }
        });
      } catch (e) {
        setErrorMessage(COMMON_REJECTION_MESSAGE);
      }
    }
  );

  const onGooglePayCancel = createEventHandler(() => {
    setScreenState(PAYMENT_SCREEN_IDLE);
  });

  const onApplePay = createEventHandler(async () => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Submit | Apple Pay',
    });

    try {
      const paymentMethodData = [
        {
          supportedMethods: 'https://apple.com/apple-pay',
          data: {
            version: 3,
            merchantIdentifier: process.env.REACT_APP_APPLE_PAY_MERCHANT_ID,
            merchantCapabilities: [
              'supports3DS',
              'supportsCredit',
              'supportsDebit',
            ],
            supportedNetworks: ['masterCard', 'visa', 'amex', 'jcb'],
            countryCode: 'JP',
          },
        },
      ];

      const paymentDetails = {
        total: {
          label: merchantName,
          amount: {
            value: `${getAmountForTheFirst(totalAmount || 0)}`,
            currency: 'JPY',
          },
        },
      };

      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails
      ) as unknown as PaymentRequestT;

      request.onmerchantvalidation = (event) => {
        const sessionPromise = PaymentAPI.requestApplePaySession().then(
          (res) => {
            return Promise.resolve(res.data.session);
          }
        );

        event.complete(sessionPromise);
      };

      const result = await request.show();
      const paymentMethod = result?.details?.token?.paymentMethod;
      const paymentData = result?.details?.token?.paymentData;

      const addCardResultAction = await dispatch(
        addPaymentMethod({
          kind: 'apple_pay',
          brand: [...(paymentMethod?.network || [])]
            .map((x: string) => x.toLowerCase?.())
            .join(''),
          last4: paymentMethod?.displayName?.slice?.(-4),
          applePayToken: Buffer.from(JSON.stringify(paymentData)).toString(
            'base64'
          ),
        })
      );

      onSubmitStart({
        onPaymentSuccess: () => result.complete('success'),
        onPaymentFailure: () => result.complete('fail'),
      });

      if (addPaymentMethod.fulfilled.match(addCardResultAction)) {
        await onSubmitEnd(addCardResultAction.payload.id);
      } else {
        setScreenState(PAYMENT_SCREEN_IDLE);
        setErrorMessage(COMMON_REJECTION_MESSAGE);
      }
    } catch (e) {
      setErrorMessage(COMMON_REJECTION_MESSAGE);
    }
  });

  useEffect(() => {
    if (!rememberMe) {
      dispatch(updateAuthPairs({ email: '' }));
    }
  }, [dispatch, rememberMe]);

  useLayoutEffect(() => {
    if (isTokenFlow) {
      navigate('/payment-approval');
    }
  }, [isTokenFlow]);

  useEffect(() => {
    Mixpanel.registerSuperProperties({
      'Number of Registered Cards': paymentMethods.length,
    });
  }, [paymentMethods]);

  useEffect(() => {
    setErrorMessage(getCardRejectionMessage(selectedPaymentMethod?.lastError));
  }, [selectedPaymentMethod]);

  useEffect(() => {
    (async () => {
      if (isValidPromotion && promotion && promotionCode) {
        const reserveDiscountResultAction = await dispatch(
          reserveDiscount({
            promotionCode,
            orderID: goods.id || '',
          })
        );

        if (reserveDiscount.fulfilled.match(reserveDiscountResultAction)) {
          dispatch(setHasAppliedPromotionCode(true));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionCode]);

  const orderStatusHandler = useCallback(async () => {
    const status = await verifyOrderStatus();

    if (status === 'succeeded' || status === 'requires_capture') {
      navigate('/payment-success');
    } else if (status === 'rejected') {
      navigate('/error/payment-rejected');
    }
  }, [verifyOrderStatus]);

  useInterval(orderStatusHandler, ORDER_STATUS_CHECK_INTERVAL);

  if (isTokenFlow) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div
        id="payment"
        className={screenState !== PAYMENT_SCREEN_IDLE ? styles.disabled : ''}
      >
        <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
          <aside>
            <Header hasBack={!rememberMe} />
            <MerchantHeader />
          </aside>
          <MainLayout hasBack={!rememberMe}>
            <h4 className={styles['form-title']}>
              月々{' '}
              <span className={cx('price-amount', styles.monthly)}>
                {currencyFormatter(getAmountForTheFirst(totalAmount))}
              </span>{' '}
              の3回後払い
            </h4>
            <div className={styles.payment}>
              <div className={styles.installments}>
                <div className={styles.steps}>
                  <i>1</i>
                  <img src={iconStepArrow} alt="" />
                  <i>2</i>
                  <img src={iconStepArrow} alt="" />
                  <i>3</i>
                </div>
                <div className={styles['three-installments']}>
                  <div className={cx(styles.installment, styles.today)}>
                    <span className={cx('price-amount', styles.amount)}>
                      {currencyFormatter(getAmountForTheFirst(totalAmount))}
                    </span>
                    <time>今日</time>
                  </div>
                  <div className={styles.installment}>
                    <span className={cx('price-amount', styles.amount)}>
                      {currencyFormatter(getAmountForTheRest(totalAmount))}
                    </span>
                    <time>{format(addMonths(new Date(), 1), 'MM月dd日')}</time>
                  </div>
                  <div className={styles.installment}>
                    <span className={cx('price-amount', styles.amount)}>
                      {currencyFormatter(getAmountForTheRest(totalAmount))}
                    </span>
                    <time>
                      {format(subDays(addMonths(new Date(), 2), 1), 'MM月dd日')}
                    </time>
                  </div>
                </div>
                <div className={cx(styles.installment, styles.total)}>
                  {`合計 `}
                  <span className={cx('price-amount', styles.highlight)}>
                    {currencyFormatter(totalAmount)}
                  </span>
                  利息・手数料無料
                </div>
              </div>
            </div>
            <PayActionForm
              errorMessage={errorMessage}
              onPay={onPay}
              onApplePay={onApplePay}
              onGooglePay={onGooglePay}
              onGooglePayCancel={onGooglePayCancel}
              onTriggerMethodSelector={onTriggerMethodSelector}
              onSubmitStart={onSubmitStart}
              totalAmount={totalAmount}
              googlePaymentRequest={googlePaymentRequest}
            />
          </MainLayout>
        </div>
      </div>
      {screenState === PAYMENT_SCREEN_CHALLENGING && (
        <ChallengeScreen
          authorizeResultHandler={authorizeResultHandler}
          onProcessing={() => {
            setScreenState(PAYMENT_SCREEN_PROCESSING);
          }}
          onError={() => {
            setErrorMessage(COMMON_REJECTION_MESSAGE);
            setScreenState(PAYMENT_SCREEN_IDLE);
          }}
        />
      )}
      {showMethodModal && (
        <PaymentMethodModal
          onSelect={onSelectPaymentMethod}
          onCancel={() => {
            setShowMethodModal(false);
          }}
        />
      )}
      <AlertModal
        title="メンテナンスのお知らせ"
        content={<p>{bankDirectMaintenanceMessage}</p>}
        visible={showBankDirectMaintenanceModal}
        onDismiss={() => {
          setShowBankDirectMaintenanceModal(false);
        }}
      />
      {screenState === PAYMENT_SCREEN_PROCESSING && <PaymentLoadingScreen />}
    </>
  );
};

export default PaymentScreen;

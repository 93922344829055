import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import {
  FC,
  FormEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import { pinCodeMaskOption } from '../../components/Form/utils';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import PasswordDescription from '../../components/PasswordDescription/PasswordDescription';
import PinResendButton from '../../components/PinResendButton';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import {
  AuthPairs,
  completePasswordReset,
  createPasswordResetSession,
  sendPasswordResetOTP,
  updateAuthPairs,
} from '../../redux/auth';
import { sanitizeOTP } from '../../utils';
import styles from './RecoverScreen.module.scss';

const RecoverScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();
  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const [otpSecret, setotpSecret] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState<APIPayload['details']>([]);
  const [otpSecretErrorMessage, setotpSecretErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { onLogin } = useOnLogin({
    onFailure: (errorCode) =>
      setErrorMessage(
        ERROR_MESSAGES.SHARED[errorCode] || ERROR_MESSAGES.SHARED.unknown
      ),
  });

  const onUpdateAuthPairs = (pairs: AuthPairs) =>
    dispatch(updateAuthPairs(pairs));

  const { email, phone, phoneLast4, newPassword } = useAppSelector(
    (state) => state.auth
  );
  const passwordResetId = useAppSelector((state) => state.auth.passwordResetId);
  const testOTPSecret = useAppSelector((state) => state.auth.testOTPSecret);

  const onResendOtp = useCallback(
    (method) =>
      passwordResetId &&
      dispatch(sendPasswordResetOTP({ passwordResetId, method })),
    [dispatch, passwordResetId]
  );

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Submit',
    });

    if (newPassword) {
      setIsLoading(true);
      onUpdateAuthPairs({ newPassword });

      const completePasswordResetResultAction = await dispatch(
        completePasswordReset({
          password: newPassword,
          passwordResetId,
          otpSecret: sanitizeOTP(testOTPSecret || otpSecret),
        })
      );

      if (
        completePasswordReset.fulfilled.match(completePasswordResetResultAction)
      ) {
        await onLogin(completePasswordResetResultAction.payload);
      } else if (
        (completePasswordResetResultAction.payload as APIPayload)?.message
      ) {
        setErrorMessage(
          ERROR_MESSAGES.PIN[
            (completePasswordResetResultAction.payload as APIPayload)?.errorCode
          ] || ERROR_MESSAGES.SHARED.unknown
        );
        setErrorDetails(
          (completePasswordResetResultAction.payload as APIPayload)?.details
        );
      } else {
        setErrorMessage(
          ERROR_MESSAGES.PIN[
            (completePasswordResetResultAction.payload as APIPayload)?.errorCode
          ] || ERROR_MESSAGES.SHARED.unknown
        );
      }
      setIsLoading(false);
    }
  };

  const onUpdateotpSecret = (event: FormEvent<HTMLInputElement>) => {
    setotpSecret(event.currentTarget.value);
    setotpSecretErrorMessage('');
  };

  useEffect(() => {
    (async () => {
      const resultAction = await dispatch(createPasswordResetSession(email));

      if (createPasswordResetSession.rejected.match(resultAction)) {
        setErrorMessage(ERROR_MESSAGES.SHARED.unknown);
      }
    })();
  }, [dispatch, email]);

  useEffect(() => {
    if (email.includes('+test') && passwordResetId) {
      dispatch(sendPasswordResetOTP({ passwordResetId, method: 'text' }));
    }
  }, [dispatch, email, passwordResetId]);

  return (
    <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
      <aside>
        <Header />
        <MerchantHeader />
      </aside>
      <MainLayout greetings="パスワードをお忘れですか？">
        <PasswordDescription desc="新しいパスワードを作成してください。" />
        <form className={styles.form} onSubmit={onSubmit}>
          <TextInput
            name="newPassword"
            type="password"
            value={newPassword}
            aria-label="パスワード（英数字8文字以上）"
            updatePairs={onUpdateAuthPairs}
            placeholder="パスワード（英数字8文字以上）"
            autoFocus
            autoCorrect="off"
            autoCapitalize="false"
            {...(errorDetails.find((detail) => detail.path === 'password') && {
              errorMessage,
            })}
          />
          <div className={styles['pin-box']}>
            <p>
              <em>•••••••{phone.slice(-4) || phoneLast4}</em>
              へ送信された6桁のセキュリティコードを入力してください。
            </p>
            <TextInput
              name="otpSecret"
              pattern="\d{3}-\d{3}"
              onChange={onUpdateotpSecret}
              value={otpSecret}
              aria-label="セキュリティコード"
              className={styles.pin}
              placeholder="888-888"
              maskOption={pinCodeMaskOption}
              autoComplete="one-time-code"
              errorMessage={otpSecretErrorMessage}
              {...(errorDetails.find(
                (detail) => detail.path === 'otpSecret'
              ) && {
                errorMessage,
              })}
              {...(testOTPSecret && {
                value: testOTPSecret,
              })}
            />
            {errorDetails.length === 0 && errorMessage && (
              <div className={styles['error-wrapper']}>
                <p>{errorMessage}</p>
              </div>
            )}
            <PinResendButton onClick={onResendOtp} />
          </div>
          <Button
            id="btn_submit"
            loading={isLoading}
            type="submit"
            label="次へ"
            disabled={
              !(
                newPassword &&
                (testOTPSecret || (otpSecret && otpSecret.length === 7))
              )
            }
          />
        </form>
      </MainLayout>
    </div>
  );
};

export default RecoverScreen;

import Mixpanel from '@smartpay/mixpanel';
import { useEffect, useState } from 'react';
import { OTP_METHOD } from '../api/types';
import useAppSelector from '../hooks/use-app-selector';
import Button from './Form/Button';
import Message from './Message/Message';
import Backdrop from './Modal/Backdrop';
import Modal from './Modal/Modal';
import styles from './PinResendButton.module.scss';

type ResendAction = (method: OTP_METHOD) => void;

const RESEND_THROTTLE = 30 * 1000;
const MSG_APPEAR_LIFETIME = 5 * 1000;

const useResendButton = (onClick: ResendAction) => {
  const [shouldDisplayPinSentMsg, setShouldDisplayPinSentMsg] = useState(false);
  const [shouldDisplayPinResendBtn, setShouldDisplayPinResendBtn] =
    useState(false);

  const displayDelayedPinResendButton = () =>
    setTimeout(() => {
      setShouldDisplayPinResendBtn(true);
    }, RESEND_THROTTLE);

  useEffect(() => {
    displayDelayedPinResendButton();
  }, []);

  const onResend = async (method: OTP_METHOD) => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Resend OTP',
    });

    await onClick(method);

    setShouldDisplayPinResendBtn(false);
    setShouldDisplayPinSentMsg(true);

    setTimeout(() => {
      setShouldDisplayPinSentMsg(false);
      displayDelayedPinResendButton();
    }, MSG_APPEAR_LIFETIME);
  };

  return {
    shouldDisplayPinResendBtn,
    shouldDisplayPinSentMsg,
    onResend,
  };
};

export default function PinResendButton({
  label,
  onClick,
}: {
  label?: string;
  onClick: ResendAction;
}) {
  const phone = useAppSelector((state) => state.auth.phone);
  const phoneLast4 = useAppSelector((state) => state.auth.phoneLast4);
  const [shouldDisplayOTPMethodSheet, setShouldDisplayOTPMethodSheet] =
    useState(false);
  const { shouldDisplayPinResendBtn, shouldDisplayPinSentMsg, onResend } =
    useResendButton(onClick);

  return (
    <>
      {shouldDisplayPinResendBtn && (
        <Button
          label={label || 'コードが届きませんか？もう一度送る'}
          variant="secondary"
          iconType="info"
          onClick={() => setShouldDisplayOTPMethodSheet(true)}
          className={styles.button}
        />
      )}
      {shouldDisplayPinSentMsg && (
        <Message message="セキュリティコードを送信しました" />
      )}
      {shouldDisplayOTPMethodSheet && (
        <>
          <Backdrop />
          <Modal>
            <div className={styles['otp-method-modal']}>
              <h5 className={styles.title}>
                電話番号の確認を
                <br />
                お願いします
              </h5>
              <p className={styles.message}>
                <em>•••••••{phone.slice(-4) || phoneLast4}</em>{' '}
                にコードを送ります
              </p>
              <div className={styles.choices}>
                <Button
                  id="btn_submit_sms"
                  type="button"
                  label="SMSで受け取る"
                  variant="outline"
                  onClick={() => {
                    setShouldDisplayOTPMethodSheet(false);
                    onResend('text');
                  }}
                />
                <Button
                  id="btn_submit_voice"
                  type="button"
                  label="電話で受け取る"
                  variant="outline"
                  onClick={() => {
                    setShouldDisplayOTPMethodSheet(false);
                    onResend('voice');
                  }}
                  className={styles['resend-voice-btn']}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

import { navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Button from '../../components/Form/Button';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import useAppSelector from '../../hooks/use-app-selector';
import useUnloadStopper from '../../hooks/use-unload-stopper';
import { onRestartDemo } from '../../utils';
import styles from './ErrorScreen.module.scss';

type ErrorCode =
  | 'payment-overdue-installments'
  | 'payment-over-limit'
  | 'payment-rejected'
  | 'session-invalid'
  | 'payment-link-not-found';

const nonPaymentErrorCodes: ErrorCode[] = [
  'session-invalid',
  'payment-link-not-found',
];

export const ERROR_HASH_ROUTES: {
  [key in ErrorCode]: { title: string | JSX.Element; description?: string };
} = {
  'session-invalid': {
    title: <div>セッションエラーが発生しました。</div>,
    description:
      '恐れ入りますが、{{merchantName}}のサイトに戻って再度お試しください。',
  },
  'payment-link-not-found': {
    title: <div>Payment Link not found</div>,
    description: 'The payment link you are looking for is not found.',
  },
  'payment-overdue-installments': {
    title: (
      <div>
        今回のお支払いは
        <br />
        承認されませんでした。
      </div>
    ),
    description:
      '延滞している分割払いがある場合は、それらを支払ってから再度スマートペイをご利用ください。',
  },
  'payment-over-limit': {
    title: (
      <div>
        今回のお支払いは
        <br />
        承認されませんでした。
      </div>
    ),
    description:
      // eslint-disable-next-line max-len
      '金額が現在の利用限度額を超えています。利用限度額はSmartpayアプリでご確認いただけます。Smartpayアプリで本人確認(eKYC)を完了すると、限度額を上げることができます。\nSmartpay Bank Directをご利用の場合、1回の取引限度額が30,000円です。本人確認が承認されると150,000円*になります。*上記の金額を下回る銀行もございます。',
  },
  'payment-rejected': {
    title: (
      <div>
        今回のお支払いは
        <br />
        承認されませんでした。
      </div>
    ),
  },
};

type Props = {
  errorCode: keyof typeof ERROR_HASH_ROUTES;
};

const REDIRECTING_TIMEOUT = 3000; // Automatically redirect after 3 secs

const ErrorScreen: FC<RouteComponentProps<Props>> = ({ errorCode }) => {
  useTrackPageView();

  const refBtnRedirect = useRef<HTMLButtonElement>(null);
  const error = ERROR_HASH_ROUTES[errorCode as ErrorCode];
  const referrer = useAppSelector((state) => state.misc.referrer);
  const cancelURL = useAppSelector((state) => state.good.cancelURL);
  const { isDemoSite, merchantName } = useAppSelector((state) => state.misc);
  const [disabled, setDisabled] = useState(false);
  const isRedirecting = useRef(false);

  const disableUnloadStopper = useUnloadStopper(
    errorCode !== 'session-invalid'
  );

  const onRedirect = useCallback(() => {
    if (isRedirecting.current) {
      return;
    }

    disableUnloadStopper();

    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Redirect to Merchant',
    });

    // back to merchant's site
    const target = cancelURL || referrer;

    if (
      (target && process.env.REACT_APP_ENV !== 'prod') ||
      (target && target.match(/^https?:\/\//))
    ) {
      isRedirecting.current = true;
      setDisabled(true);
      window.location.href = target;
    }
  }, [cancelURL, referrer, disableUnloadStopper]);

  const onRestart = useCallback(() => {
    disableUnloadStopper();
    isRedirecting.current = true;
    setDisabled(true);
    onRestartDemo();
  }, [disableUnloadStopper]);

  useEffect(() => {
    if (!error) {
      navigate('/');
    }
  }, [error]);

  useLayoutEffect(() => {
    refBtnRedirect?.current?.focus();

    const timer = setTimeout(() => {
      if (isDemoSite) {
        onRestart();
      } else {
        onRedirect();
      }
    }, REDIRECTING_TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, [isDemoSite, onRedirect, onRestart, refBtnRedirect]);

  return !error ? null : (
    <div className="rwd-wrapper no-rwd">
      <Header hasBack={false} />
      <MainLayout hasBack={false}>
        <div className={styles.content}>
          <h2 className={styles.title}>{error.title}</h2>
          {error.description?.split('\n').map((text, i) => {
            return (
              <p className={styles.desc} key={i}>
                {text.replace(
                  '{{merchantName}}',
                  merchantName || 'ショッピング'
                )}
              </p>
            );
          })}
          {errorCode && !nonPaymentErrorCodes.includes(errorCode) && (
            <a className={styles.faq} href="/faq">
              注文が拒否された理由は？
            </a>
          )}
          {!isDemoSite && merchantName && (
            <Button
              inputRef={refBtnRedirect}
              type="button"
              className="btn"
              onClick={onRedirect}
              label={`${merchantName}に戻ります`}
              disabled={disabled}
            />
          )}
          {isDemoSite && (
            <Button
              type="button"
              className="btn"
              onClick={onRestart}
              inputRef={refBtnRedirect}
              label="一からやり直す"
              disabled={disabled}
            />
          )}
        </div>
      </MainLayout>
    </div>
  );
};

export default ErrorScreen;

import { RouteComponentProps, navigate, useParams } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import capitalize from 'lodash.capitalize';
import { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../..';
import illSsoApple from '../../assets/ill-sso-apple.svg';
import illSsoGoogle from '../../assets/ill-sso-google.svg';
import Button from '../../components/Form/Button';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../hooks/use-app-selector';
import { getOAuthPayload } from '../../hooks/use-on-login';
import useResponsive from '../../hooks/use-responsive';
import { createAssociateAttempt } from '../../redux/auth';
import styles from './AssociateScreen.module.scss';

enum SSO_SERVICES {
  google = 'google',
  apple = 'apple',
}
type SSO_SERVICE = keyof typeof SSO_SERVICES | '';

const illustrations: Record<SSO_SERVICE, string> = {
  google: illSsoGoogle,
  apple: illSsoApple,
  '': '',
};

export const getSsoService = (input?: string) => {
  if (input && Object.values(SSO_SERVICES).includes(input as SSO_SERVICES)) {
    return input as SSO_SERVICE;
  }

  return '';
};

const AssociateScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();
  const service = getSsoService(useParams().service);
  const serviceName = capitalize(service);
  const { isMobile } = useResponsive();
  const [isLinking, setIsLinking] = useState(false);
  const rememberMe = useAppSelector((state) => state.misc.rememberMe);
  const idToken = useAppSelector((state) => state.auth.idToken);
  const sessionIdWithSignature = useAppSelector(
    (state) => state.misc.sessionIdWithSignature
  );

  const onLink = useCallback(async () => {
    setIsLinking(true);

    const payload = getOAuthPayload(idToken);
    const createAssociateAttemptAction = await dispatch(
      createAssociateAttempt({
        idToken: payload.idToken,
        issuer: payload.issuer,
        clientId: payload.clientId,
      })
    );

    if (createAssociateAttempt.fulfilled.match(createAssociateAttemptAction)) {
      navigate(`/associate/${service}/pin`, { replace: true });
    } else {
      setIsLinking(false);
    }
  }, [dispatch, idToken, service]);

  useEffect(() => {
    if (!service) {
      navigate(`/${sessionIdWithSignature}`, { replace: true });
    }
  }, [service, sessionIdWithSignature]);

  if (!service) {
    return null;
  }

  return (
    <div className={cx('rwd-wrapper')}>
      <aside>
        <Header hasBack={!rememberMe} />
        {!isMobile && <MerchantHeader />}
      </aside>
      <MainLayout
        hasBack={!rememberMe}
        showFooter={false}
        showLogo={!isMobile}
        className={styles.layout}
      >
        <div className={styles.content}>
          <img src={illustrations[service]} alt="" />
          <div>
            <h2 className={styles.title}>{`${serviceName}と連携しますか？`}</h2>
            <p>
              {`Smartpayのアカウントをお持ちです。
              連携すると、${serviceName}アカウントでログインできるようになります。`}
            </p>
          </div>

          <div className={styles.bottom}>
            <Button
              type="button"
              label="同意してリンクする"
              loading={isLinking}
              onClick={onLink}
            />
            <Button
              className={styles.cancel}
              type="button"
              label="キャンセル"
              disabled={isLinking}
              onClick={() => {
                navigate(`/${sessionIdWithSignature}`);
              }}
            />
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default AssociateScreen;

import { AxiosResponse } from 'axios';
import publicAxiosInstance from './public-axios-instance';
import { OTP_METHOD } from './types';

export type LoginParams = { email: string; password: string };
export type LoginWithOtpParams = { loginAttemptId: string; otpSecret: string };
export type LoginAttemptParams = {
  email: string;
  password: string;
  uniqueId: string;
};
export type SsoParams = {
  code: string;
  clientId: string;
  redirectUri: string;
};

export type SendLoginOtpParams = {
  loginAttemptId: string;
  method: OTP_METHOD;
};

type CreatePasswordResetSessionParams = { email: string };

export type CompletePasswordResetSessionParams = {
  passwordResetId: string;
  password: string;
  otpSecret: string;
};

export type ResendPasswordResetSessionParams = {
  passwordResetId: string;
  method: OTP_METHOD;
};

export type SuccessfulLoginResponse = { accessToken: string };
export type ChallengingLoginResponse = { id: string };
export type SendLoginOtpResponse = { phoneLast4: string };
export type SsoAssociateResponse = { idToken: string };

type LoginReturn = Promise<AxiosResponse<SuccessfulLoginResponse>>;
type LoginAttemptReturn = Promise<
  AxiosResponse<SuccessfulLoginResponse | ChallengingLoginResponse>
>;
type SendLoginOtpReturn = Promise<AxiosResponse<SendLoginOtpResponse>>;
type SsoReturn = Promise<
  AxiosResponse<SuccessfulLoginResponse | SsoAssociateResponse>
>;

type CreatePasswordResetReturn = Promise<
  AxiosResponse<{ id: string; phoneLast4: string }>
>;

const LoginAPI = {
  loginAttempt: ({
    email,
    password,
    uniqueId,
  }: LoginAttemptParams): LoginAttemptReturn =>
    publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/auth/login/attempt`,
      {
        emailAddress: email,
        password,
        uniqueId,
      },
      { withCredentials: true }
    ),
  sendLoginOTP: ({
    loginAttemptId,
    method,
  }: SendLoginOtpParams): SendLoginOtpReturn =>
    publicAxiosInstance.post(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/auth/${loginAttemptId}/challenge`,
      { otpDispatchMethod: method }
    ),
  loginWithOtp: ({
    loginAttemptId,
    otpSecret,
  }: LoginWithOtpParams): LoginReturn =>
    publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/auth/login`,
      {
        signinAttempt: loginAttemptId,
        otpSecret,
      },
      { withCredentials: true }
    ),
  ssoGoogle: ({ code, clientId, redirectUri }: SsoParams): SsoReturn =>
    publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/sso/google`,
      {
        code,
        clientId,
        redirectUri,
      },
      { withCredentials: true }
    ),
  ssoApple: ({ code, clientId, redirectUri }: SsoParams): SsoReturn =>
    publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/sso/apple`,
      {
        code,
        clientId,
        redirectUri,
      },
      { withCredentials: true }
    ),
  refreshToken: (): LoginReturn =>
    publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/auth/refresh`,
      {},
      { withCredentials: true }
    ),
  createPasswordResetSession: ({
    email,
  }: CreatePasswordResetSessionParams): CreatePasswordResetReturn =>
    publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/password-resets`,
      {
        emailAddress: email,
      }
    ),
  completePasswordReset: ({
    passwordResetId,
    password,
    otpSecret,
  }: CompletePasswordResetSessionParams) =>
    publicAxiosInstance.patch(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/password-resets/${passwordResetId}`,
      {
        password,
        otpSecret,
      },
      { withCredentials: true }
    ),
  sendPasswordResetOTP: ({
    passwordResetId,
    method,
  }: ResendPasswordResetSessionParams) =>
    publicAxiosInstance.post(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/password-resets/${passwordResetId}/retries`,
      { otpDispatchMethod: method }
    ),
};

export default LoginAPI;

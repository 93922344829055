import { navigate, RouteComponentProps } from '@reach/router';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import randomstring from 'randomstring';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AppleSignin, { AppleAuthResponse } from 'react-apple-signin-auth';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import LogotypeVertical from '../../assets/logotype-vertical.svg';
import XMark from '../../assets/x-mark.svg';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
// eslint-disable-next-line max-len
import PreferredPlatformModal from '../../components/PreferredPlatformModal/PreferredPlatformModal';
import merchants from '../../contentful/merchants.json';
import useAppSelector from '../../hooks/use-app-selector';
import usePrepareApp from '../../hooks/use-prepare-app';
import {
  AuthPairs,
  checkEmailValidity,
  createSignupSession,
  ssoApple,
  ssoGoogle,
  updateAuthPairs,
} from '../../redux/auth';
import { updateGoodPairs } from '../../redux/good';
import { updateMiscPairs } from '../../redux/misc';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import styles from './LoginScreen.module.scss';

import IconSsoApple from '../../assets/sso-apple.svg';
import IconSsoGoogle from '../../assets/sso-google.svg';
import useOnLogin from '../../hooks/use-on-login';

export type SNS = 'google' | 'apple' | '';
type SigninFlow = 'default' | SNS;

const screen = 'Login';

const LoginScreen: FC<RouteComponentProps> = ({ location }) => {
  useTrackPageView({ screen });

  const dispatch = useAppDispatch();

  const {
    loading,
    preferredPlatform,
    showPreferredPlatformPrompt,
    setShowPreferredPlatformPrompt,
    errorCode: prepareErrorCode,
  } = usePrepareApp();

  const { 'merchant-name': demoMerchantName } = useAppSelector(
    (state) => state.demo
  );
  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const isTestSession = useAppSelector((state) => state.misc.isTestSession);
  const email = useAppSelector((state) => state.auth.email);
  const {
    successURL,
    cancelURL,
    data: { merchantId },
  } = useAppSelector((state) => state.good);
  const { isDemoSite, rememberMe, merchantName } = useAppSelector(
    (state) => state.misc
  );
  const { isLoginViaAppFeatureEnabled, isSSOGoogleEnabled, isSSOAppleEnabled } =
    useAppSelector((state) => state.featureFlag);

  const merchant = useMemo(
    () => merchants.find((m) => m.merchantId === merchantId),
    [merchantId]
  );
  const merchantHameDesc = merchantName ? `${merchantName}で` : '';

  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(
    (location?.state as { errorMessage: string })?.errorMessage || ''
  );
  const [hasUnknownNeverBounceError, setHasUnknownNeverBounceError] =
    useState(false);
  const [loginInfoMessage, setLoginInfoMessage] = useState('');
  const [processingFlow, setProcessingFlow] = useState<SigninFlow>('');

  const { onLogin } = useOnLogin({
    onFailure: (errorCode) =>
      setErrorMessage(
        ERROR_MESSAGES.SHARED[errorCode] || ERROR_MESSAGES.SHARED.unknown
      ),
  });

  const onUpdateAuthPairs = (pairs: AuthPairs) => {
    setLoginErrorMessage('');
    setLoginInfoMessage('');
    setHasUnknownNeverBounceError(false);
    setErrorMessage('');
    dispatch(updateAuthPairs({ email: pairs.email?.trim() }));
  };

  const onSubmit = useCallback(
    async (event?: SyntheticEvent<HTMLFormElement>) => {
      event?.preventDefault();

      if (email && cancelURL && successURL) {
        if (!rememberMe) {
          // Only track Login click event for non-remembered user
          Mixpanel.trackAction({
            action: 'Click',
            itemName: 'Submit',
            screen,
          });
        }

        dispatch(updateAuthPairs({ email }));
        dispatch(
          updateGoodPairs({
            successURL,
            cancelURL,
          })
        );

        setProcessingFlow('default');

        try {
          const signupResultAction = await dispatch(
            createSignupSession({ emailAddress: email })
          );

          if (createSignupSession.fulfilled.match(signupResultAction)) {
            if (signupResultAction.payload.isTestAccount !== isTestSession) {
              setErrorMessage(ERROR_MESSAGES.SHARED['live.test.mismatch']);
            } else {
              const checkEmailValidityResultAction = await dispatch(
                checkEmailValidity(email)
              );

              if (
                checkEmailValidity.fulfilled.match(
                  checkEmailValidityResultAction
                )
              ) {
                const { result } = checkEmailValidityResultAction.payload.data;

                if (result === 'valid') {
                  navigate('phone');
                } else if (result === 'invalid') {
                  setLoginErrorMessage(ERROR_MESSAGES.LOGIN['invalid.email']);
                } else if (
                  result === 'unknown' &&
                  !hasUnknownNeverBounceError
                ) {
                  setLoginInfoMessage(
                    'メールアドレスに間違いはございませんか？誤字脱字がないかご確認ください。'
                  );
                  setHasUnknownNeverBounceError(true);
                } else if (result === 'unknown' && hasUnknownNeverBounceError) {
                  // If this unknown error happened for the 2nd time in a row,
                  // accept it and send to BE
                  navigate('phone');
                }
              }
            }
          } else if (
            (signupResultAction.payload as APIPayload)?.errorCode ===
            'consumers.signup.email-address-registered'
          ) {
            navigate('/password');
          } else {
            dispatch(updateMiscPairs({ rememberMe: false }));
            setLoginErrorMessage(
              ERROR_MESSAGES.LOGIN[
                (signupResultAction.payload as APIPayload)?.errorCode
              ] || ERROR_MESSAGES.SHARED.unknown
            );
          }
        } catch {
          setErrorMessage(ERROR_MESSAGES.SHARED.unknown);
        } finally {
          setProcessingFlow('');
        }
      }
    },
    [
      cancelURL,
      dispatch,
      email,
      hasUnknownNeverBounceError,
      isTestSession,
      rememberMe,
      successURL,
    ]
  );

  useEffect(() => {
    if (prepareErrorCode) {
      setErrorMessage(
        ERROR_MESSAGES.SHARED[prepareErrorCode] || ERROR_MESSAGES.SHARED.unknown
      );
    }
  }, [prepareErrorCode]);

  useEffect(() => {
    const state = location?.state as { errorCode: string };

    if (state?.errorCode) {
      setErrorMessage(ERROR_MESSAGES.SHARED[state.errorCode] || '');
    }
  }, [location]);

  const ssoGoogleLogin = useGoogleLogin({
    onSuccess: async (response: CodeResponse) => {
      if (response.code) {
        const ssoResultAction = await dispatch(
          ssoGoogle({
            code: response.code,
            clientId: process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID || '',
            redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI || '',
          })
        );

        if (ssoGoogle.fulfilled.match(ssoResultAction)) {
          onLogin(ssoResultAction.payload);
        } else {
          setProcessingFlow('');
          setErrorMessage(ERROR_MESSAGES.SHARED.unknown);
        }
      }
    },
    onError: () => {
      setProcessingFlow('');
    },
    onNonOAuthError: () => {
      setProcessingFlow('');
    },
    redirect_uri: process.env.REACT_APP_OAUTH_REDIRECT_URI || '',
    flow: 'auth-code',
  });

  const ssoAppleLogin = async (response: AppleAuthResponse) => {
    if (response.authorization.code) {
      const ssoResultAction = await dispatch(
        ssoApple({
          code: response.authorization.code,
          clientId: process.env.REACT_APP_OAUTH_APPLE_CLIENT_ID || '',
          redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI || '',
        })
      );

      if (ssoApple.fulfilled.match(ssoResultAction)) {
        onLogin(ssoResultAction.payload);
      } else {
        setProcessingFlow('');
        setErrorMessage(ERROR_MESSAGES.SHARED.unknown);
      }
    }
  };

  // Checkout session data is loading
  if (loading) {
    return <LoadingScreen />;
  }

  // Remembered user, waiting for token refresh
  if (
    rememberMe &&
    email &&
    !isDemoSite &&
    !loginErrorMessage &&
    !errorMessage
  ) {
    return (
      <>
        <LoadingScreen />
        {/* This is required if the user checked remember me */}
        <PreferredPlatformModal
          preferredPlatform={preferredPlatform}
          visible={showPreferredPlatformPrompt}
          onDismiss={() => setShowPreferredPlatformPrompt(false)}
        />
      </>
    );
  }

  return (
    <>
      <div
        className={cx('rwd-wrapper', 'login', isTokenFlow ? 'token-flow' : '')}
      >
        <aside>
          <Header hasBack={false} />
          <MerchantHeader />
        </aside>
        <MainLayout
          hasBack={false}
          greetings="ログインまた登録"
          desc1={`<b>${
            demoMerchantName || merchantHameDesc
          }の購入を完了するには、</b>スマートペイでログインまたは登録をしてください。`}
          desc2="メールアドレスを入力してください。"
          logo={
            <div className={styles['logo-container']}>
              {merchant?.logo ? (
                <div className={styles.logos}>
                  <img
                    className={styles.logo}
                    src={LogotypeVertical}
                    alt="Smartpay"
                    height={53.49}
                  />
                  <img className={styles['x-mark']} src={XMark} alt="x" />
                  <img
                    className={styles.logo}
                    src={merchant?.logo}
                    alt="Merchant"
                    height={53.49}
                  />
                </div>
              ) : (
                <img
                  className={styles.logo}
                  src={LogotypeVertical}
                  alt="Smartpay"
                  height={64}
                />
              )}
              <span className={styles.highlight}>
                利息・手数料無料の３回払い
              </span>
            </div>
          }
        >
          <form className={styles.form} onSubmit={onSubmit}>
            <fieldset>
              <TextInput
                name="email"
                type="email"
                value={email}
                aria-label="メールアドレス"
                placeholder="hello@smartpay.co"
                autoComplete="email"
                updatePairs={onUpdateAuthPairs}
                errorMessage={loginErrorMessage}
                infoMessage={loginInfoMessage}
                tip={
                  !showPreferredPlatformPrompt && isDemoSite ? (
                    <>
                      ユーザー名が<em>+test</em>で終わるメール （例：
                      <em>hello+test@smartpay.co</em>）をご利用ください。
                    </>
                  ) : null
                }
                shouldDisplayTipOnInit
              />
              {/* This is a fake hidden field
              to trick password management tool to work */}
              <TextInput
                name="password"
                type="password"
                className={styles.password}
              />
              {errorMessage && (
                <div className={styles['error-wrapper']}>
                  <p>{errorMessage}</p>
                </div>
              )}
            </fieldset>
            <Button
              id="btn_submit"
              loading={processingFlow === 'default'}
              type="submit"
              label={hasUnknownNeverBounceError ? '確認する' : '次へ'}
              disabled={
                (!!processingFlow && processingFlow !== 'default') ||
                !email ||
                !!loginErrorMessage ||
                !!errorMessage
              }
            />
          </form>
          <div className={styles.or} data-text="または" />
          <div className={styles['login-btns']}>
            {isLoginViaAppFeatureEnabled && (
              <Button
                id="btn_qr_code"
                label="QRコードでログイン"
                onClick={() => navigate('/qr-code')}
                variant="outline"
              />
            )}
            {isSSOAppleEnabled && (
              <AppleSignin
                authOptions={{
                  clientId: process.env.REACT_APP_OAUTH_APPLE_CLIENT_ID || '',
                  scope: 'email name',
                  redirectURI: process.env.REACT_APP_OAUTH_REDIRECT_URI || '',
                  nonce: randomstring.generate(),
                  usePopup: true,
                }}
                onSuccess={ssoAppleLogin}
                onError={(error) => {
                  setProcessingFlow('');
                  // eslint-disable-next-line no-console
                  console.error(error);
                }}
                skipScript={false}
                render={(props) => (
                  <Button
                    id="btn_sso_apple"
                    label={
                      <>
                        <img src={IconSsoApple} alt="" width={20} height={20} />
                        Appleでサインイン
                      </>
                    }
                    className={styles['qr-code-btn']}
                    variant="black-outline"
                    loading={processingFlow === 'apple'}
                    disabled={!!processingFlow && processingFlow !== 'apple'}
                    {...props}
                    onClick={() => {
                      setProcessingFlow('apple');
                      (props.onClick as () => void)?.();
                    }}
                  />
                )}
              />
            )}
            {isSSOGoogleEnabled && (
              <Button
                id="btn_sso_google"
                label={
                  <>
                    <img src={IconSsoGoogle} alt="" width={20} height={20} />
                    Googleでサインイン
                  </>
                }
                onClick={() => {
                  setProcessingFlow('google');
                  ssoGoogleLogin();
                }}
                className={styles['qr-code-btn']}
                variant="black-outline"
                loading={processingFlow === 'google'}
                disabled={!!processingFlow && processingFlow !== 'google'}
              />
            )}
          </div>
        </MainLayout>
      </div>
      <PreferredPlatformModal
        preferredPlatform={preferredPlatform}
        visible={showPreferredPlatformPrompt}
        onDismiss={() => setShowPreferredPlatformPrompt(false)}
      />
    </>
  );
};

export default LoginScreen;

import { navigate } from '@reach/router';
import { useCallback } from 'react';
import { useAppDispatch } from '..';
import { ProfileReturn } from '../api/profile';
import {
  createSignupSession,
  getIsTestAccount,
  sendLoginOTP,
  updateAuthPairs,
} from '../redux/auth';
import { getPaymentMethods } from '../redux/payment-method';
import { getProfile } from '../redux/profile';
import useAppSelector from './use-app-selector';
import usePrecheckOrder from './use-precheck-order';

type Params = {
  onSuccess?: () => void;
  onFailure?: (msg: string) => void;
};

export type LoginPayload = {
  accessToken?: string;
  loginAttemptId?: string;
  idToken?: string;
  anonymousId?: string;
  isTestAccount?: boolean;
};

export const getOAuthPayload = (idToken: string) => {
  const payload = JSON.parse(atob(idToken.split('.')[1]));

  switch (payload.iss) {
    case process.env.REACT_APP_OAUTH_GOOGLE_ISSUER:
      return {
        service: 'google',
        idToken,
        issuer: payload.iss,
        clientId: process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID || '',
      };
    case process.env.REACT_APP_OAUTH_APPLE_ISSUER:
      return {
        service: 'apple',
        idToken,
        issuer: payload.iss,
        clientId: process.env.REACT_APP_OAUTH_APPLE_CLIENT_ID || '',
      };

    default:
      return {
        service: '',
        idToken,
        issuer: '',
        clientId: '',
      };
  }
};

const useOnLogin = ({ onSuccess, onFailure }: Params) => {
  const dispatch = useAppDispatch();
  const isTestSession = useAppSelector((state) => state.misc.isTestSession);
  const { onPrecheckOrder } = usePrecheckOrder();

  const onLogin = useCallback(
    async ({
      accessToken,
      loginAttemptId,
      idToken,
      anonymousId,
      isTestAccount,
    }: LoginPayload = {}) => {
      if (loginAttemptId) {
        await dispatch(sendLoginOTP({ loginAttemptId, method: 'text' }));
        navigate('/2fa');

        return;
      }

      if (idToken) {
        const payload = getOAuthPayload(idToken);
        const signupResultAction = await dispatch(
          createSignupSession({
            idToken: payload.idToken,
            issuer: payload.issuer,
            clientId: payload.clientId,
          })
        );

        if (createSignupSession.fulfilled.match(signupResultAction)) {
          if (signupResultAction.payload.isTestAccount !== isTestSession) {
            onFailure?.('live.test.mismatch');
          } else {
            // SSO, skips password screen
            navigate('/phone');
          }
        } else {
          navigate(`/associate/${payload.service}`);
        }

        return;
      }

      if (!accessToken) {
        onFailure?.('unknown');

        return;
      }

      const fallbackedIsTestAccount =
        isTestAccount !== undefined
          ? isTestAccount
          : getIsTestAccount(accessToken);

      if (fallbackedIsTestAccount !== isTestSession) {
        onFailure?.('live.test.mismatch');

        return;
      }

      await dispatch(
        updateAuthPairs({
          anonymousId,
          accessToken,
          isTestAccount,
        })
      );

      const shouldContinue = await onPrecheckOrder({
        onError: () => {
          onFailure?.('unknown');
        },
      });

      if (!shouldContinue) {
        return;
      }

      await dispatch(getPaymentMethods());

      const profileResultAction = await dispatch(getProfile());

      if (getProfile.fulfilled.match(profileResultAction)) {
        const { status } =
          profileResultAction.payload as Awaited<ProfileReturn>['data'];

        onSuccess?.();

        if (status === 'completed') {
          navigate('/payment', { replace: true });
        } else {
          navigate('/profile', { replace: true });
        }
      } else {
        onFailure?.('unknown');
      }
    },
    [dispatch, isTestSession, onFailure, onPrecheckOrder, onSuccess]
  );

  return { onLogin };
};

export default useOnLogin;
